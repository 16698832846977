import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [];

export const dictionary = {
		"/": [~7],
		"/(static)/accessibilite": [18],
		"/admin": [28,[3]],
		"/admin/contributions": [29,[3]],
		"/admin/moderation": [30,[3]],
		"/admin/services": [31,[3]],
		"/admin/services/[slug]": [32,[3]],
		"/admin/structures": [33,[3]],
		"/admin/structures/creer": [35,[3]],
		"/admin/structures/[slug]": [34,[3]],
		"/annuaire-collectivite": [36],
		"/auth/connexion": [37,[4]],
		"/auth/invitation": [38,[4]],
		"/auth/pc-callback": [39,[4]],
		"/auth/pc-callback/[token]": [40,[4]],
		"/auth/pc-logout": [41,[4]],
		"/auth/rattachement": [42,[4]],
		"/(static)/cgu": [19],
		"/(static)/cgu/cgu-20221213": [20],
		"/(static)/cgu/cgu-20230515": [21],
		"/(static)/cgu/cgu-20230720": [22],
		"/(static)/cgu/cgu-20240506": [23],
		"/(static)/cgu/validation": [24],
		"/contribuer": [43],
		"/contribuer/merci": [44],
		"/contribuer/saisie": [45],
		"/favoris": [46],
		"/(static)/mentions-legales": [25],
		"/mes-alertes": [47],
		"/mes-alertes/[searchId]": [48],
		"/(modeles-services)/modeles/creer": [10],
		"/(modeles-services)/modeles/[slug]": [8],
		"/(modeles-services)/modeles/[slug]/editer": [9],
		"/mon-compte": [49,[5]],
		"/(static)/nos-partenaires": [~26],
		"/orientations": [50],
		"/(static)/politique-de-confidentialite": [27],
		"/recherche-textuelle": [52],
		"/recherche": [51],
		"/(modeles-services)/services/creer": [17],
		"/(modeles-services)/services/[slug]": [11],
		"/(modeles-services)/services/[slug]/editer": [12],
		"/(modeles-services)/services/[slug]/orienter": [13,[2]],
		"/(modeles-services)/services/[slug]/orienter/demande": [14,[2]],
		"/(modeles-services)/services/[slug]/orienter/merci": [15,[2]],
		"/(modeles-services)/services/[slug]/orienter/moderation": [16,[2]],
		"/stats": [53],
		"/structures/[slug]": [54,[6]],
		"/structures/[slug]/antennes": [55,[6]],
		"/structures/[slug]/collaborateurs": [56,[6]],
		"/structures/[slug]/editer": [57,[6]],
		"/structures/[slug]/modeles": [58,[6]],
		"/structures/[slug]/services": [59,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';